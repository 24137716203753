import React from 'react';
import { Link } from 'gatsby';

import { Row, Col } from '/src/components/atoms/GridRow';
import YouTubeEmbed from '/src/components/atoms/YouTubeEmbed';
import Layout from '/src/components/layout';
import SEO from '/src/components/atoms/Seo';
import Button from '/src/components/atoms/Button';
import ContentSection from '/src/components/atoms/ContentSection';

const AboutPage = () => (
  <Layout hideHeader>
    <SEO title="About" />
    <ContentSection banner parallax center bgSrc="/images/uploads/eoa_atr.png">
      <h1 className="heading1">We're Weird</h1>
      <Button cta component={Link} to="/booking">
        Contact Us
      </Button>
    </ContentSection>
    <ContentSection light>
      <Row center vertGutter>
        <Col width={6}>
          <h2>About EoA</h2>
          <p>
            Eye on Attraction is a progressive/alternative rock band with
            mind-blowing energy and technical proficiency.
          </p>

          <p>
            Taking influence from bands like Coheed and Cambria, Paramore, and
            The Dear Hunter, female-fronted prog rockers Eye on Attraction pride
            themselves on providing catchy hooks, complex instrumentation, and
            an intense live show.
          </p>

          <p>
            After a national tour with Adelitas Way in 2017, Eye on Attraction
            released their single “Pleasantville” (mastered by Howie Weinberg of
            Muse/Rush fame) to critical acclaim.
          </p>

          <p>
            The band’s members have amassed a large following in the nine years
            they have been touring nationally, alongside acts such as The Pretty
            Reckless, Sevendust, Gemini Syndrome, Upon a Burning Body, and most
            recently, All That Remains.
          </p>

          <p>
            Eye on Attraction leaves a long lasting impression on every crowd
            they play for, often being dubbed “the best live band we have ever
            seen.”
          </p>
        </Col>
      </Row>
      <Row center vertGutter>
        <Col width={6}>
          <h2 className="sr-only">Kentucky Lyric Video</h2>
          <YouTubeEmbed title="Kentucky Lyric Video" youtubeId="x51Lyo3Qk2k" />
        </Col>
      </Row>
    </ContentSection>
  </Layout>
);

export default AboutPage;
