import React from 'react';
import './index.scss';

const YouTubeEmbed = ({ title, youtubeId }) => (
  <div
    role="group"
    aria-label={`Video embed for "${title}`}
    className="YouTubeEmbed"
  >
    <iframe
      title={title}
      src={`https://www.youtube.com/embed/${youtubeId}`}
      frameBorder="0"
      allowFullScreen
      className="YouTubeEmbed__iframe"
    />
  </div>
);

export default YouTubeEmbed;
